// component
import Iconify from "../../../components/iconify/Iconify";
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "Dashboard",
    path: "/admin/dashboard",
    icon: <Iconify icon="carbon:dashboard-reference" />,
  },
  // {
  //   title: "Sub Admin",
  //   path: "/admin/sub-admin",
  //   icon: <Iconify icon="material-symbols:admin-panel-settings-outline" />,
  // },
  // {
  //   title: "Team Leader",
  //   path: "/admin/team-lead",
  //   icon: <Iconify icon="ant-design:team-outlined" />,
  // },
  // {
  //   title: "Agent",
  //   path: "/admin/agent",
  //   icon: <Iconify icon="fluent:person-circle-32-regular" />,
  // },
  // {
  //   title: "Tele Caller",
  //   path: "/admin/tele-caller",
  //   icon: <Iconify icon="mdi:face-agent" />,
  // },
  // {
  //   title: "Sales Executive",
  //   path: "/admin/sales-executive",
  //   icon: <Iconify icon="carbon:sales-ops" />,
  // },

  {
    title: "Role",
    icon: <Iconify icon="material-symbols:admin-panel-settings-outline" />,
    children: [
      {
        title: "Sub Admin",
        path: "/admin/sub-admin",
        icon: <Iconify icon="material-symbols:admin-panel-settings-outline" />,
      },
      {
        title: "Team Leader",
        path: "/admin/team-lead",
        icon: <Iconify icon="ant-design:team-outlined" />,
      },
      {
        title: "Tele Caller",
        path: "/admin/tele-caller",
        icon: <Iconify icon="mdi:face-agent" />,
      },
      {
        title: "Sales Executive",
        path: "/admin/sales-executive",
        icon: <Iconify icon="carbon:sales-ops" />,
      },
    ],
  },
  {
    title: "Agent",
    icon: <Iconify icon="icon-park-solid:people-unknown" />,
    children: [
      {
        title: "All Agent",
        path: "/admin/agent",
        icon: <Iconify icon="fluent:person-circle-32-regular" />,
      },
      {
        title: "Pending Agent",
        path: "/admin/pending-agent",
        icon: <Iconify icon="fluent:people-edit-20-regular" />,
      },
    ],
  },
  {
    title: "Properties",
    icon: <Iconify icon="tabler:home-star" />,
    children: [
      {
        title: "Create Property",
        path: "/admin/create-property",
        icon: <Iconify icon="tabler:home-plus" />,
      },
      {
        title: "All Property",
        path: "/admin/all-property",
        icon: <Iconify icon="mdi:house-city-outline" />,
      },
    ],
  },
  {
    title: "Projects",
    icon: <Iconify icon="ic:twotone-apartment" />,
    children: [
      {
        title: "Create Project",
        path: "/admin/create-project",
        icon: <Iconify icon="mdi:house-group-add" />,
      },
      {
        title: "All Project",
        path: "/admin/all-project",
        icon: <Iconify icon="ic:outline-home-work" />,
      },
    ],
  },
  {
    title: "Team",
    icon: <Iconify icon="fluent:people-team-24-filled" />,
    children: [
      {
        title: "Create Team",
        path: "/admin/create-team",
        icon: <Iconify icon="hugeicons:add-team" />,
      },
      {
        title: "All Team",
        path: "/admin/all-team",
        icon: <Iconify icon="material-symbols-light:team-dashboard-outline" />,
      },
    ],
  },
  {
    title: "Leads",
    icon: <Iconify icon="material-symbols:admin-panel-settings-outline" />,
    children: [
      {
        title: "Basic Leads",
        path: "/admin/all-leads",
        icon: <Iconify icon="fluent:person-star-32-filled" />,
      },
      {
        title: "Prospects",
        path: "/admin/Prospects",
        icon: <Iconify icon="mdi:human-queue" />,
      },
      {
        title: "Opportunity",
        path: "/admin/opportunity",
        icon: <Iconify icon="material-symbols:physical-therapy-outline" />,
      },
      {
        title: "Site Visit",
        path: "/admin/sitevisit",
        icon: <Iconify icon="bxs:home-heart" />,
      },
      {
        title: "Bookings",
        path: "/admin/bookings",
        icon: <Iconify icon="tabler:home-check" />,
      },
    ],
  },
];

export default navConfig;
