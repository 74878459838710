import React, { useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
  Typography,
  Container,
  Box,
  Card,
} from "@mui/material";
import { patchData, postData } from "../../webService/webService";
import PROJECTROUTES from "../../API_Routes/Project/Project_Routers";
import { ToastContainer } from "react-toastify";

const initialFormData = {
  builderName: "",
  title: "",
  description: "",
  keywords: "",
  location: "",
  isFocus: false,
  region: "",
  brokerage: "",
  landArea: 0,
  towerNo: 0,
  storeys: 0,
  readyToMove: false,
  possessionDate: false,
  mapView: "",
  siteUrl: "",
  brochureUrl: "",
  projectImageUrl: "",
  RERA_Number: "",
  slab_min: 0,
  slab_max: 0,
  bhk: 0,
  price: 0,
};

const CreateProject = ({
  editData,
  handleCloseEdit,
  selectedUserId,
  viewMode,
}) => {
  const accessToken = sessionStorage.getItem("Token");
  const [formData, setFormData] = useState(initialFormData);
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  useEffect(() => {
    if (editData) {
      setFormData(editData);
    }
  }, [editData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (editData) {
      const res = await patchData(
        PROJECTROUTES.PATCHPROJECT(selectedUserId),
        formData,
        accessToken
      );
      if (res) {
        handleCloseEdit();
      }
    } else {
      const res = await postData(
        PROJECTROUTES.AddPROJECT,
        formData,
        accessToken
      );
      if (res) {
        setFormData(initialFormData);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <Card className="p-3">
        <h5>
          {viewMode
            ? "View Project"
            : editData
            ? "Edit Project"
            : "Create Project"}
        </h5>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="builderName"
                label="Builder Name"
                value={formData.builderName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="title"
                label="Title"
                value={formData.title}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                value={formData.description}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="keywords"
                label="Keywords"
                value={formData.keywords}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="location"
                label="Location"
                value={formData.location}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="region"
                label="Region"
                value={formData.region}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="brokerage"
                label="Brokerage"
                value={formData.brokerage}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="landArea"
                label="Land Area"
                type="number"
                value={formData.landArea}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="towerNo"
                label="Tower Number"
                type="number"
                value={formData.towerNo}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="storeys"
                label="Storeys"
                type="number"
                value={formData.storeys}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isFocus"
                    checked={formData.isFocus}
                    onChange={handleChange}
                  />
                }
                label="Is Focus"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="readyToMove"
                    checked={formData.readyToMove}
                    onChange={handleChange}
                  />
                }
                label="Ready To Move"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="possessionDate"
                    checked={formData.possessionDate}
                    onChange={handleChange}
                  />
                }
                label="Possession Date"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="mapView"
                label="Map View"
                value={formData.mapView}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="siteUrl"
                label="Site URL"
                value={formData.siteUrl}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="brochureUrl"
                label="Brochure URL"
                value={formData.brochureUrl}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="projectImageUrl"
                label="Project Image URL"
                value={formData.projectImageUrl}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="RERA_Number"
                label="RERA Number"
                value={formData.RERA_Number}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="slab_min"
                label="Slab Minimum"
                type="number"
                value={formData.slab_min}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="slab_max"
                label="Slab Maximum"
                type="number"
                value={formData.slab_max}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="bhk"
                label="BHK"
                type="number"
                value={formData.bhk}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="price"
                label="Price"
                type="number"
                value={formData.price}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
          <div className="d-flex justify-content-end mt-4">
            <Button
              variant="contained"
              className="btn-danger"
              onClick={handleCloseEdit}
            >
              Close
            </Button>
            {!viewMode && (
              <Button type="submit" variant="contained" className="btn-green">
                Submit
              </Button>
            )}
          </div>
        </form>
      </Card>
    </>
  );
};

export default CreateProject;
