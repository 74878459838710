import { useState, useEffect } from "react";
import { getData } from "../../webService/webService";

const useFetchData = (route, accessToken, initialLimit = 1000) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(initialLimit);

  const fetchData = (limit) => {
    const params = { page: 1, limit };
    getData(route, params, accessToken)
      .then((res) => {
        console.log(res);
        setData(res.data[0].paginatedResults);
        setTotal(res.data[0].totalPages);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchData(total);
  }, []);
  useEffect(() => {
    if (total > 1000) fetchData(total);
  }, [total]);

  return [data, total, setTotal];
};

export default useFetchData;
