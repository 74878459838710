import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Grid,
} from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useDispatch } from "react-redux";
import USERSROUTES from "../../API_Routes/User/User_Routers";
import { showConfirmationDialog } from "../../utils/sweetalertUtils";
import Iconify from "../../components/iconify/Iconify";
import Scrollbar from "../../components/scrollbar/Scrollbar";
import { UserListHead } from "../../sections/@dashboard/user";
import {
  deleteData,
  getData,
  patchData,
  postData,
} from "../../webService/webService";
import { setView } from "../../redux/slices/LoaderSlice";
import BOOKINGROUTES from "../../API_Routes/Booking/Booking_Routers";
import PROJECTROUTES from "../../API_Routes/Project/Project_Routers";
import PROPERTYROUTES from "../../API_Routes/Property/Property_Routers";
import LEADROUTES from "../../API_Routes/Lead/Lead_Routers";
import useFetchData from "../../components/GetAll/getallmasterdata";

export default function Allbooking({ Role, Currentstatus }) {
  const TABLE_HEAD = [
    { id: "Lead Name", label: "Lead Name", alignRight: false },
    { id: "Agent Name", label: "Agent Name", alignRight: false },
    {
      id: "Projects Builder Name",
      label: "Projects Builder Name",
      alignRight: false,
    },
    {
      id: "Property Owner Name",
      label: "Property Owner Name",
      alignRight: false,
    },
    { id: "Action", label: "Action", alignRight: false },
  ];

  const dispatch = useDispatch();
  const [USERLIST, setUSERLIST] = useState(null);
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const accessToken = sessionStorage.getItem("Token");
  const [open, setOpen] = useState(null);
  const [active, setActive] = useState();
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);

  const [adduseropen, setadduserOpen] = useState(false);
  const [formData, setFormData] = useState({
    projectId: "",
    propertyId: "",
    leadId: "",
    agentId: "",

    bookingAmount: 0,
    agreementAmount: 0,
    clientPaymentPercentage: 0,
    agentCommissionPercentage: 0,
    agentReferralDepthOneCommissionPercentage: 0,
    agentReferralDepthTwoCommissionPercentage: 0,
    currentStatus: "",

    isBookingApproved: false,
  });

  const handleadduserOpen = (data) => {
    setActive(data);
    setadduserOpen(true);
    if (data !== "Edit") {
      setFormData({
        projectId: "",
        propertyId: "",
        leadId: "",
        agentId: "",

        bookingAmount: 0,
        agreementAmount: 0,
        clientPaymentPercentage: 0,
        agentCommissionPercentage: 0,
        agentReferralDepthOneCommissionPercentage: 0,
        agentReferralDepthTwoCommissionPercentage: 0,
        currentStatus: "",

        isBookingApproved: false,
      });
    }
  };

  const handleadduserClose = () => setadduserOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (active === "Add") {
      postData(BOOKINGROUTES.AddBOOKING, formData, accessToken)
        .then((res) => {
          console.log(res);
          setFormData({
            projectId: "",
            propertyId: "",
            leadId: "",
            agentId: "",

            bookingAmount: 0,
            agreementAmount: 0,
            clientPaymentPercentage: 0,
            agentCommissionPercentage: 0,
            agentReferralDepthOneCommissionPercentage: 0,
            agentReferralDepthTwoCommissionPercentage: 0,
            currentStatus: "",

            isBookingApproved: false,
          });
          fetchdata(currentPage);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      handleadduserClose();
    } else {
      patchData(
        BOOKINGROUTES.PATCHBOOKING(selectedUserId),
        formData,
        accessToken
      )
        .then((res) => {
          setOpen(null);
          fetchdata(currentPage);
          setFormData({
            projectId: "",
            propertyId: "",
            leadId: "",
            agentId: "",

            bookingAmount: 0,
            agreementAmount: 0,
            clientPaymentPercentage: 0,
            agentCommissionPercentage: 0,
            agentReferralDepthOneCommissionPercentage: 0,
            agentReferralDepthTwoCommissionPercentage: 0,
            currentStatus: "",

            isBookingApproved: false,
          });
          setSelectedUserId(null);
        })
        .catch((error) => {
          setOpen(null);
          setSelectedUserId(null);
          console.error("Error:", error);
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      handleadduserClose();
    }
  };

  const fetchdata = (page) => {
    const params = {
      page: page,
      limit: limit,
    };
    getData(BOOKINGROUTES.GETALLBOOKING(isAuthenticated), params, accessToken)
      .then((res) => {
        if (res.status === 404) {
          setUSERLIST(null);
          setTotalPages(1);
        } else {
          setUSERLIST(res.data[0].paginatedResults);
          setTotalPages(res.data[0].totalPages);
        }
        console.log(res);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchdata(currentPage);
  }, [currentPage, limit, Role, Currentstatus]);

  const DeleteId = async (val) => {
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      deleteData(BOOKINGROUTES.DELETEBOOKING(val), accessToken)
        .then((res) => {
          setOpen(null);
          fetchdata(currentPage);
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  useEffect(() => {
    if (selectedUserId) {
      const EditUserData = USERLIST.find((item) => item._id === selectedUserId);
      setFormData({
        projectId: EditUserData.Projects_Data[0]._id || "",
        propertyId: EditUserData.Properties_Data[0]._id || "",
        leadId: EditUserData.Lead_Data[0]._id || "",
        agentId: EditUserData.AgentUser_Data[0]._id || "",

        bookingAmount: EditUserData.bookingAmount || 0,
        agreementAmount: EditUserData.agreementAmount || 0,
        clientPaymentPercentage: EditUserData.clientPaymentPercentage || 0,
        agentCommissionPercentage: EditUserData.agentCommissionPercentage || 0,
        agentReferralDepthOneCommissionPercentage:
          EditUserData.agentReferralDepthOneCommissionPercentage || 0,
        agentReferralDepthTwoCommissionPercentage:
          EditUserData.agentReferralDepthTwoCommissionPercentage || 0,
        currentStatus: EditUserData.currentStatus || "",
        isBookingApproved: EditUserData.isBookingApproved || false,
      });
    }
  }, [selectedUserId]);

  console.log(formData, "formData");

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedUserId(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };

  const [allProject, allProjectTotal, setAllProjectTotal] = useFetchData(
    PROJECTROUTES.GETALLPROJECT,
    accessToken
  );
  const [allProperty, allPropertyTotal, setAllPropertyTotal] = useFetchData(
    PROPERTYROUTES.GETALLPROPERTY,
    accessToken
  );
  const [allAgent, allAgentTotal, setAllAgentTotal] = useFetchData(
    USERSROUTES.GETUSER("Agent"),
    accessToken
  );
  const [allLead, allLeadTotal, setAllLeadTotal] = useFetchData(
    LEADROUTES.GETALLLEAD,
    accessToken
  );

  useEffect(() => {
    if (allProjectTotal > 1000) setAllProjectTotal(allProjectTotal);
    if (allPropertyTotal > 1000) setAllPropertyTotal(allPropertyTotal);
    if (allAgentTotal > 1000) setAllAgentTotal(allAgentTotal);
    if (allLeadTotal > 1000) setAllLeadTotal(allLeadTotal);
  }, [allProjectTotal, allPropertyTotal, allAgentTotal, allLeadTotal]);

  return (
    <>
      <Helmet>
        <title>All Bookings</title>
      </Helmet>

      <ToastContainer />

      <Modal open={adduseropen} onClose={handleadduserClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          <h5>{active === "Add" ? "Add Booking" : "Edit Booking"}</h5>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>ProjectId</InputLabel>
                <Select
                  name="projectId"
                  value={formData.projectId}
                  onChange={handleChange}
                  label="ProjectId"
                >
                  {allProject.map((project) => (
                    <MenuItem key={project._id} value={project._id}>
                      {project.builderName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>PropertyId</InputLabel>
                <Select
                  name="propertyId"
                  value={formData.propertyId}
                  onChange={handleChange}
                  label="PropertyId"
                >
                  {allProperty.map((property) => (
                    <MenuItem key={property._id} value={property._id}>
                      {property.ownerName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>LeadId</InputLabel>
                <Select
                  name="leadId"
                  value={formData.leadId}
                  onChange={handleChange}
                  label="LeadId"
                >
                  {allLead.map((lead) => (
                    <MenuItem key={lead._id} value={lead._id}>
                      {lead.clientName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>AgentId</InputLabel>
                <Select
                  name="agentId"
                  value={formData.agentId}
                  onChange={handleChange}
                  label="AgentId"
                >
                  {allAgent.map((agent) => (
                    <MenuItem key={agent._id} value={agent._id}>
                      {agent.firstName}
                      {agent.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Booking Amount"
                name="bookingAmount"
                type="number"
                value={formData.bookingAmount}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Agreement Amount"
                name="agreementAmount"
                type="number"
                value={formData.agreementAmount}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Client Payment Percentage"
                name="clientPaymentPercentage"
                type="number"
                value={formData.clientPaymentPercentage}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Agent Commission Percentage"
                name="agentCommissionPercentage"
                type="number"
                value={formData.agentCommissionPercentage}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Agent Referral Depth One Commission Percentage"
                name="agentReferralDepthOneCommissionPercentage"
                type="number"
                value={formData.agentReferralDepthOneCommissionPercentage}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Agent Referral Depth Two Commission Percentage"
                name="agentReferralDepthTwoCommissionPercentage"
                type="number"
                value={formData.agentReferralDepthTwoCommissionPercentage}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Current Status"
                name="currentStatus"
                value={formData.currentStatus}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="isBookingApproved">
                  Is Booking Approved
                </InputLabel>
                <Select
                  label="Is Booking Approved"
                  id="isBookingApproved"
                  name="isBookingApproved"
                  value={formData.isBookingApproved}
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <div className="d-flex justify-content-end mt-3">
            <Button
              variant="contained"
              color="error"
              className="mx-3 btn-danger"
              onClick={handleadduserClose}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="btn-green"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Booking
          </Typography>
          {Currentstatus !== "waiting for approval" && (
            <Button
              variant="contained"
              className="main-col-new"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleadduserOpen("Add")}
            >
              Add Booking
            </Button>
          )}
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST?.length}
                />
                {USERLIST === null ? (
                  <TableBody>
                    <TableRow className="w-100 m-3">
                      <TableCell colSpan={TABLE_HEAD.length}>No Data</TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {USERLIST?.map((row) => {
                      const {
                        _id,
                        Lead_Data,
                        AgentUser_Data,
                        Projects_Data,
                        Properties_Data,
                      } = row;
                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="subtitle2"
                                noWrap
                                style={{ marginLeft: "10px" }}
                              >
                                {Lead_Data[0].clientName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {AgentUser_Data[0].firstName}{" "}
                            {AgentUser_Data[0].lastName}
                          </TableCell>
                          <TableCell align="left">
                            {Projects_Data[0].builderName}
                          </TableCell>
                          <TableCell align="left">
                            {Properties_Data[0].ownerName}
                          </TableCell>
                          <TableCell align="left">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => handleOpenMenu(event, _id)}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <div className="d-flex flex-wrap justify-content-end m-3">
            <FormControl variant="outlined" size="small">
              <InputLabel id="limit-label">Limit</InputLabel>
              <Select
                labelId="limit-label"
                id="limit-select"
                value={limit}
                onChange={handleLimitChange}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </FormControl>
            <Button onClick={handlePrevPage} disabled={currentPage === 1}>
              <Iconify icon={"grommet-icons:previous"} />
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <Iconify icon={"grommet-icons:next"} />
            </Button>
          </div>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleadduserOpen("Edit");
          }}
        >
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem
          sx={{ color: "error.main" }}
          onClick={() => {
            DeleteId(selectedUserId);
          }}
        >
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
