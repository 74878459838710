import { configureStore } from "@reduxjs/toolkit";
import LogInSlice from "../redux/slices/LoginSlice";
import LoaderReducer from "../redux/slices/LoaderSlice";
import LeadStageSlice from "./slices/LeadStageSlice";
export const store = configureStore({
  reducer: {
    project: LogInSlice,
    Loader: LoaderReducer,
    LeadStage: LeadStageSlice,
  },
});
