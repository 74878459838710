import { createSlice } from "@reduxjs/toolkit";


const LogInSlice = createSlice({
  name: "project",
  initialState: {
    Token: "",
  },
  reducers: {
    setToken: (state, action) => {
      state.Token = action.payload;
    },
  },
});

export const { setToken } = LogInSlice.actions;
export default LogInSlice.reducer;