import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { useEffect, useState } from "react";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
// components
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead } from "../sections/@dashboard/user";
// mock
// import USERLIST from "../_mock/user";
import {
  deleteData,
  getData,
  patchData,
  postData,
} from "../webService/webService";
import USERSROUTES from "../API_Routes/User/User_Routers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showConfirmationDialog } from "../utils/sweetalertUtils";
import { setView } from "../redux/slices/LoaderSlice";
import { useDispatch } from "react-redux";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function UserPage({ Role, Currentstatus }) {
  const TABLE_HEAD = [
    { id: "Name", label: "Name", alignRight: false },
    { id: "Role", label: "Role", alignRight: false },
    { id: "Email", label: "Email", alignRight: false },
    ...(Role === "Agent" && Currentstatus === "waiting for approval"
      ? [{ id: "Approve Status", label: "Approve Status", alignRight: false }]
      : []),
    { id: "Action", label: "Action", alignRight: false },
  ];

  console.log(Role, "Role");
  console.log(Currentstatus, "status");
  const dispatch = useDispatch();
  const formattedRole = Role.split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  const [USERLIST, setUSERLIST] = useState(null);
  const accessToken = sessionStorage.getItem("Token");
  const [open, setOpen] = useState(null);
  const [active, setActive] = useState();
  const [selectedUserId, setSelectedUserId] = useState(null);
  console.log(USERLIST, "USERLIST123");

  // Table Conditions
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedUserId(id);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };

  // Add User
  const [adduseropen, setadduserOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: Role,
  });

  const handleadduserOpen = (data) => {
    setActive(data);
    setadduserOpen(true);
    if (data === "Add") {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        role: Role,
      });
    }
  };

  const handleadduserClose = () => setadduserOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const params = { userData: formData, roleData: {} };
    dispatch(setView(true));
    if (active === "Add") {
      postData(USERSROUTES.AddUSER, formData, accessToken)
        .then((res) => {
          console.log(res);
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            role: Role,
          });
          fetchdata(currentPage);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      handleadduserClose();
    } else {
      patchData(
        USERSROUTES.PATCHUSER(Role, selectedUserId),
        params,
        accessToken
      )
        .then((res) => {
          setOpen(null);
          fetchdata(currentPage);
          setSelectedUserId(null);
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            role: Role,
          });
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      handleadduserClose();
    }
  };

  const fetchdata = (page) => {
    const params = {
      page: page,
      limit: limit,
      ...(Currentstatus === "waiting for approval" && {
        isuserapproved: false,
      }),
    };
    getData(USERSROUTES.GETUSER(Role), params, accessToken)
      .then((res) => {
        if (res.status === 404) {
          setUSERLIST(null);
          setTotalPages(1);
        } else {
          setUSERLIST(res.data[0].paginatedResults);
          setTotalPages(res.data[0].totalPages);
        }
        console.log(res);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchdata(currentPage);
  }, [currentPage, limit, Role, Currentstatus]);

  const DeleteId = async (val) => {
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      deleteData(USERSROUTES.DELETEUSER(Role, val), accessToken)
        .then((res) => {
          setOpen(null);
          fetchdata(currentPage);
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  useEffect(() => {
    if (selectedUserId) {
      const EditUserData = USERLIST.find((item) => item._id === selectedUserId);
      setFormData({
        firstName: EditUserData.firstName,
        lastName: EditUserData.lastName,
        email: EditUserData.email,
        role: Role,
      });
    }
  }, [selectedUserId]);

  useEffect(() => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      role: Role,
    });
  }, [Role]);

  const handleChangeApprove = async (event, _id) => {
    debugger;
    if (Currentstatus === "waiting for approval") {
      const body = {
        agentid: _id,
      };
      await postData(USERSROUTES.USERAPPROVAL, body, accessToken);
      fetchdata(currentPage);
    } else {
      // const value = event.target.value;
      // const body = {
      //   isuserapproved: value,
      // }
      // await patchData(
      //   USERSROUTES.PATCHUSER(Role, selectedUserId),
      //   formData,
      //   accessToken
      // );
    }
  };

  return (
    <>
      <Helmet>
        <title>{Role}</title>
      </Helmet>

      <ToastContainer />

      <Modal open={adduseropen} onClose={handleadduserClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          <h5>
            {active} {formattedRole}
          </h5>
          <div className="d-flex flex-wrap">
            <div className="col-6 px-1">
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                margin="normal"
              />
            </div>
            <div className="col-6 px-1">
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                margin="normal"
              />
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-6 px-1">
              <TextField
                fullWidth
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                margin="normal"
                autoComplete="off"
              />
            </div>
            <div className="col-6 px-1">
              <TextField
                fullWidth
                label="Password"
                name="password"
                type="text"
                value={formData.password}
                onChange={handleChange}
                margin="normal"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <Button
              variant="contained"
              color="error"
              className="mx-3 btn-danger"
              onClick={handleadduserClose}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="btn-green"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {formattedRole}
          </Typography>
          {Currentstatus !== "waiting for approval" && (
            <Button
              variant="contained"
              className="main-col-new"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleadduserOpen("Add")}
            >
              Add {formattedRole}
            </Button>
          )}
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST?.length}
                />
                {USERLIST === null ? (
                  <TableBody>
                    <TableRow className="w-100 m-3">
                      <TableCell colSpan={TABLE_HEAD.length}>No Data</TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {USERLIST?.map((row) => {
                      const {
                        _id,
                        firstName,
                        lastName,
                        role,
                        email,
                        isUserApproved,
                      } = row;
                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="subtitle2"
                                noWrap
                                style={{ marginLeft: "10px" }}
                              >
                                {firstName} {lastName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{role}</TableCell>
                          <TableCell align="left">{email}</TableCell>
                          {Role === "Agent" &&
                            Currentstatus === "waiting for approval" && (
                              <TableCell align="left">
                                <Select
                                  value={isUserApproved ? "Yes" : "No"}
                                  onChange={(event) =>
                                    handleChangeApprove(event, _id)
                                  }
                                  sx={{ width: "80px", height: "30px" }}
                                >
                                  <MenuItem value="Yes">Yes</MenuItem>
                                  <MenuItem value="No">No</MenuItem>
                                </Select>
                              </TableCell>
                            )}

                          <TableCell align="left">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => handleOpenMenu(event, _id)}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <div className="d-flex flex-wrap justify-content-end m-3">
            <FormControl variant="outlined" size="small">
              <InputLabel id="limit-label">Limit</InputLabel>
              <Select
                labelId="limit-label"
                id="limit-select"
                value={limit}
                onChange={handleLimitChange}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </FormControl>
            <Button onClick={handlePrevPage} disabled={currentPage === 1}>
              <Iconify icon={"grommet-icons:previous"} />
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <Iconify icon={"grommet-icons:next"} />
            </Button>
          </div>
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleadduserOpen("Edit");
          }}
        >
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem
          sx={{ color: "error.main" }}
          onClick={() => {
            DeleteId(selectedUserId);
          }}
        >
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
