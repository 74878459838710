import React, { useEffect, useState } from "react";
import PROJECTROUTES from "../../API_Routes/Project/Project_Routers";
import { deleteData, getData } from "../../webService/webService";
import { Helmet } from "react-helmet-async";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import Scrollbar from "../../components/scrollbar";
import { UserListHead } from "../../sections/@dashboard/user";
import Iconify from "../../components/iconify";
import LEADROUTES from "../../API_Routes/Lead/Lead_Routers";
import { showConfirmationDialog } from "../../utils/sweetalertUtils";
import LeadForm from "./createLead";
import { useSelector } from "react-redux";
import Prospect from "./prospect";

function AllLeads({ Currentstage }) {
  console.log("Currentstage", Currentstage);
  // Table
  const accessToken = sessionStorage.getItem("Token");
  const [open, setOpen] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [USERLIST, setUSERLIST] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);

  const formatDate = (isoDate) => {
    if (!isoDate) return "";

    const date = new Date(isoDate);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedUserId(id);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };
  const fetchdata = (page) => {
    const params = {
      page: page,
      limit: limit,
      stage: Currentstage,
    };
    getData(LEADROUTES.GETALLLEAD, params, accessToken)
      .then((res) => {
        console.log(res);
        if (res.status === 404) {
          setUSERLIST(null);
          setTotalPages(1);
        } else {
          setUSERLIST(res.data[0].paginatedResults);
          setTotalPages(res.data[0].totalPages);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    fetchdata(currentPage);
    setShowEdit(false);
    setOpen(null);
  }, [currentPage, limit, Currentstage]);

  const TABLE_HEAD = [
    { id: "ClientName", label: "ClientName", alignRight: false },
    { id: "ClientContact", label: "Contact", alignRight: false },
    { id: "Location", label: "Location", alignRight: false },
    { id: "Action", label: "Action", alignRight: false },
  ];

  const DeleteId = async (val) => {
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      deleteData(LEADROUTES.DELETELEAD(val), accessToken)
        .then((res) => {
          setOpen(null);
          fetchdata(currentPage);
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [viewMode, setViewMode] = useState(false);
  const Handleshow = (data) => {
    let EditUserData;
    if (data !== "Add") {
      EditUserData = USERLIST.find((item) => item._id === selectedUserId);
      console.log(EditUserData, "EditUserData");
    }
    if (data === "View") {
      setViewMode(true);
    }
    setShowEdit(true);
    setEditData({
      mobileNumber: EditUserData.mobileNumber,
      budget: EditUserData.budget,
      bedConfiguration: EditUserData.bedConfiguration,
      score: EditUserData.score,
      clientName: EditUserData.clientName,
      clientContact: EditUserData.clientContact,
      email: EditUserData.email,
      location: EditUserData.location,
      region: EditUserData.region,
      activityStatus: EditUserData.activityStatus,
      nationality: EditUserData.nationality,
      comments: EditUserData.comments,
      reference: EditUserData.reference,
      stage: EditUserData.stage,
      requirement: EditUserData.requirement,
      followUpNote: EditUserData.followUpNote,
      source: EditUserData.source,
      branch: EditUserData.branch,
      keywords: EditUserData.keywords,
      notification: EditUserData.notification,
      visitDate: formatDate(EditUserData.visitDate),
      assignedDate: formatDate(EditUserData.assignedDate),
      followUpDate: formatDate(EditUserData.followUpDate),
      lastUpdatedDate: formatDate(EditUserData.lastUpdatedDate),
      activityDate: formatDate(EditUserData.activityDate),
      posessionTimeline: formatDate(EditUserData.posessionTimeline),
      prospect: {
        remarks: EditUserData.prospect?.remarks || "",
      },
      oppurtunity: {
        remarks: EditUserData.oppurtunity?.remarks || "",
      },
    });
  };
  const handleCloseEdit = () => {
    setShowEdit(false);
    setViewMode(false);
    setEditData(null);
    setOpen(null);
    fetchdata(currentPage);
  };

  const LEADSTAGE = useSelector((state) => state?.LeadStage);
  console.log(LEADSTAGE, "LEADSTAGE");
  return (
    <>
      <Helmet>
        <title>All Leads</title>
      </Helmet>

      <ToastContainer />
      {LEADSTAGE.Stage && <Prospect />}

      {showEdit ? (
        <>
          <LeadForm
            editData={editData}
            handleCloseEdit={handleCloseEdit}
            selectedUserId={selectedUserId}
            viewMode={viewMode}
            Currentstage={Currentstage}
          />
        </>
      ) : (
        <>
          <Container>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography variant="h4" gutterBottom>
                {Currentstage === "BASIC"
                  ? "All Leads"
                  : Currentstage === "PROSPECT"
                  ? "Prospect"
                  : Currentstage === "OPPURTUNITY"
                  ? "Oppurtunity"
                  : ""}
              </Typography>
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => Handleshow("Add")}
              >
                Add Lead
              </Button>
            </Stack>

            <Card>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      headLabel={TABLE_HEAD}
                      rowCount={USERLIST?.length}
                    />
                    {USERLIST === null ? (
                      <TableBody>
                        <TableRow className="w-100 m-3">
                          <TableCell colSpan={TABLE_HEAD.length}>
                            No Data
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        {USERLIST?.map((row) => {
                          const { _id, clientName, clientContact, location } =
                            row;
                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    style={{ marginLeft: "10px" }}
                                  >
                                    {clientName}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                {clientContact}
                              </TableCell>
                              <TableCell align="left">{location}</TableCell>
                              <TableCell align="left">
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(event) =>
                                    handleOpenMenu(event, _id)
                                  }
                                >
                                  <Iconify icon={"eva:more-vertical-fill"} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <div className="d-flex flex-wrap justify-content-end m-3">
                <FormControl variant="outlined" size="small">
                  <InputLabel id="limit-label">Limit</InputLabel>
                  <Select
                    labelId="limit-label"
                    id="limit-select"
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                  </Select>
                </FormControl>
                <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                  <Iconify icon={"grommet-icons:previous"} />
                </Button>
                <Button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <Iconify icon={"grommet-icons:next"} />
                </Button>
              </div>
            </Card>
          </Container>

          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                "& .MuiMenuItem-root": {
                  px: 1,
                  typography: "body2",
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                Handleshow("View");
              }}
            >
              <Iconify icon={"iconoir:eye-solid"} sx={{ mr: 2 }} />
              View
            </MenuItem>
            <MenuItem
              onClick={() => {
                Handleshow("Edit");
              }}
            >
              <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
              Edit
            </MenuItem>
            <MenuItem
              sx={{ color: "error.main" }}
              onClick={() => {
                DeleteId(selectedUserId);
              }}
            >
              <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
              Delete
            </MenuItem>
          </Popover>
        </>
      )}
    </>
  );
}

export default AllLeads;
