import React, { useState, useRef, useEffect } from "react";
import { AsyncPaginate } from "react-select-async-paginate";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    maxHeight: "200px",
    overflowY: "auto",
  }),
};

const ReusablePaginatedSelect = ({
  fetchDataFunction,
  accessToken,
  getOptions,
  initialPage = 1,
  limit = 5, // Adjust limit based on your API pagination
  isMulti = false, // Added isMulti prop for multi-select functionality
  value: propValue, // Added propValue for controlled component support
  onChange, // Added onChange handler
}) => {
  const [selectedValue, setSelectedValue] = useState(propValue || (isMulti ? [] : null)); // Initialize with propValue or empty array if multi
  const [page, setPage] = useState(initialPage);
  const [loading, setLoading] = useState(false); // State to manage loading state

  const loadingRef = useRef(false); // Ref to track if loading is already in progress

  useEffect(() => {
    setSelectedValue(propValue); // Update selectedValue when propValue changes
  }, [propValue]);

  const loadOptions = async (search, loadedOptions, { page }) => {
    if (loadingRef.current) return { options: loadedOptions }; // If already loading, return current options

    try {
      loadingRef.current = true; // Set loading flag
      const params = { page, limit };
      const res = await fetchDataFunction(params, accessToken);

      const newOptions = getOptions(res);

      const totalFetchedPages = res.data[0].totalPages;
      const hasMore = page < totalFetchedPages;

      return {
        options: newOptions,
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Error:", error);
      return {
        options: [],
        hasMore: false,
        additional: {
          page: initialPage,
        },
      };
    } finally {
      loadingRef.current = false; // Reset loading flag
    }
  };

  const handleChange = (newValue) => {
    setSelectedValue(newValue); // Update local state

    if (onChange) {
      // Pass selected value to parent component if onChange handler is provided
      onChange(newValue);
    }
  };

  return (
    <AsyncPaginate
      value={selectedValue}
      loadOptions={loadOptions}
      additional={{
        page: page,
      }}
      onChange={handleChange}
      isMulti={isMulti} // Enable multi-select if isMulti prop is true
      styles={customStyles}
      onMenuScrollToBottom={() => {
        if (!loading) {
          setLoading(true);
          setPage((prevPage) => prevPage + 1);
        }
      }}
    />
  );
};

export default ReusablePaginatedSelect;
