const formatDateTime = (isoDate) => {
  if (!isoDate) return "";

  const date = new Date(isoDate);
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export default formatDateTime;
