import React, { useState, useEffect } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Card,
} from "@mui/material";
import { patchData, postData } from "../../webService/webService";
import PROPERTYROUTES from "../../API_Routes/Property/Property_Routers";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet-async";

const initialFormData = {
  ownerName: "",
  title: "",
  description: "",
  keywords: "",
  location: "",
  isFocus: false,
  region: "",
  brokerage: "",
  landArea: 0,
  towerNo: 0,
  storeys: 0,
  readyToMove: false,
  possessionDate: "",
  mapView: "",
  siteUrl: "",
  brochureUrl: "",
  projectImageUrl: "",
  RERA_Number: "",
  slab_min: 0,
  slab_max: 0,
  bhk: 0,
  price: 0,
};

const CreateProperty = ({
  editData,
  handleCloseEdit,
  selectedUserId,
  viewMode,
}) => {
  const accessToken = sessionStorage.getItem("Token");
  const [formData, setFormData] = useState(initialFormData);
  useEffect(() => {
    if (editData) {
      setFormData(editData);
    }
  }, [editData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    if (editData) {
      const res = await patchData(
        PROPERTYROUTES.PATCHPROPERTY(selectedUserId),
        formData,
        accessToken
      );
      if (res) {
        handleCloseEdit();
      }
    } else {
      const res = await postData(
        PROPERTYROUTES.AddPROPERTY,
        formData,
        accessToken
      );
      if (res) {
        setFormData({
          ownerName: "",
          title: "",
          description: "",
          keywords: "",
          location: "",
          isFocus: false,
          region: "",
          brokerage: "",
          landArea: 0,
          towerNo: 0,
          storeys: 0,
          readyToMove: false,
          possessionDate: "",
          mapView: "",
          siteUrl: "",
          brochureUrl: "",
          projectImageUrl: "",
          RERA_Number: "",
          slab_min: 0,
          slab_max: 0,
          bhk: 0,
          price: 0,
        });
        // handleCloseEdit();
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Create Properties</title>
      </Helmet>
      <ToastContainer />
      <Card className="p-3">
        <h5>
          {viewMode
            ? "View Property"
            : editData
            ? "Edit Property"
            : "Create Property"}
        </h5>
        <form onSubmit={handleSubmit}>
          <div className="d-flex flex-wrap justify-content-between create-property align-items-center">
            <div className="col-6 px-1">
              <TextField
                name="ownerName"
                label="Owner Name"
                value={formData.ownerName}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="col-6 px-1">
              <TextField
                name="title"
                label="Title"
                value={formData.title}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="col-12 px-1">
              <TextField
                name="description"
                label="Description"
                value={formData.description}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="col-6 px-1">
              <TextField
                name="keywords"
                label="Keywords"
                value={formData.keywords}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="col-6 px-1">
              <TextField
                name="location"
                label="Location"
                value={formData.location}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="col-6 px-1">
              <TextField
                name="region"
                label="Region"
                value={formData.region}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="col-6 px-1">
              <TextField
                name="brokerage"
                label="Brokerage"
                value={formData.brokerage}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="col-6 px-1">
              <TextField
                name="towerNo"
                label="Tower No"
                type="number"
                value={formData.towerNo}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="col-6 px-1">
              <TextField
                name="storeys"
                label="Storeys"
                type="number"
                value={formData.storeys}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="col-6 px-1">
              <TextField
                name="mapView"
                label="Map View"
                value={formData.mapView}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="col-6 px-1">
              <TextField
                name="siteUrl"
                label="Site URL"
                value={formData.siteUrl}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
            {/* <div className="col-6 px-1">
              <TextField
                name="brochureUrl"
                label="Brochure URL"
                value={formData.brochureUrl}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div> */}
            {/* <div className="col-6 px-1">
              <TextField
                name="projectImageUrl"
                label="Project Image URL"
                value={formData.projectImageUrl}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div> */}
            {/* <div className="col-6 px-1">
              <TextField
                name="RERA_Number"
                label="RERA Number"
                value={formData.RERA_Number}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div> */}
            <div className="col-6 px-1">
              <TextField
                name="slab_min"
                label="Slab Min"
                type="number"
                value={formData.slab_min}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="col-6 px-1">
              <TextField
                name="slab_max"
                label="Slab Max"
                type="number"
                value={formData.slab_max}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="col-6 px-1">
              <TextField
                name="bhk"
                label="BHK"
                type="number"
                value={formData.bhk}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="col-6 px-1">
              <TextField
                name="price"
                label="Price"
                type="number"
                value={formData.price}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="col-6 px-1">
              <TextField
                name="possessionDate"
                label="Possession Date"
                type="date"
                value={formData.possessionDate}
                onChange={handleChange}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-6 px-1">
              <FormControlLabel
                control={
                  <Checkbox
                    name="isFocus"
                    checked={formData.isFocus}
                    onChange={handleChange}
                  />
                }
                label="Is Focus"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="readyToMove"
                    checked={formData.readyToMove}
                    onChange={handleChange}
                  />
                }
                label="Ready to Move"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button
              variant="contained"
              className="btn-danger"
              onClick={handleCloseEdit}
            >
              Close
            </Button>
            {!viewMode && (
              <Button type="submit" variant="contained" className="btn-green">
                Submit
              </Button>
            )}
          </div>
        </form>
      </Card>
    </>
  );
};

export default CreateProperty;
