import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postData } from "../../../webService/webService";
// @mui
import { Stack, IconButton, InputAdornment, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import { setToken } from "src/redux/slices/LoginSlice";
import { useDispatch } from "react-redux";
import USERSROUTES from "src/API_Routes/User/User_Routers";
// ----------------------------------------------------------------------

export default function LoginForm({ onLogin }) {
  const dispatch = useDispatch();
  const [mail, setMail] = useState("");
  const [pwd, setPwd] = useState("");
  const [role, setRole] = useState("None");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    const LoginParams = {
      email: mail.trim(),
      password: pwd,
      role: role,
    };
    if (mail === "" && pwd === "" && role === "None") {
      toast.error("All The Filed's Are Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      postData(USERSROUTES.LOGIN, LoginParams, null)
        .then((res) => {
          switch (res.status) {
            case 200:
              sessionStorage.setItem("UserMail", mail);
              sessionStorage.setItem("Token", res?.data?.sessionToken);
              sessionStorage.setItem("UserId", res?.data?.userId);
              dispatch(setToken(res?.data?.sessionToken));
              onLogin(role);
              switch (role) {
                case "Admin":
                  navigate("/admin/dashboard", { replace: true });
                  break;
                default:
                  console.log("No Routes");
                  break;
              }
              break;
            case 500:
              toast.error("Error while logging in: Server error", {
                position: toast.POSITION.TOP_RIGHT,
              });
              break;
            case 400:
              toast.error("Incorrect Password", {
                position: toast.POSITION.TOP_RIGHT,
              });
              break;
            case 404:
              toast.error("User Not Found", {
                position: toast.POSITION.TOP_RIGHT,
              });
              break;
            default:
              toast.error("Unexpected Error occurred", {
                position: toast.POSITION.TOP_RIGHT,
              });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleChangeRole = (event) => {
    setRole(event.target.value);
  };

  return (
    <>
      <ToastContainer />
      <Stack spacing={3}>
        <h4 className="text-center fw-bold">Log In</h4>
        <TextField
          name="email"
          label="Email address"
          value={mail}
          size="small"
          onChange={(e) => setMail(e.target.value)}
          autoComplete="off"
          required
        />
        <TextField
          autoComplete="off"
          size="small"
          name="password"
          label="Password"
          required
          type={showPassword ? "text" : "password"}
          value={pwd}
          onChange={(e) => setPwd(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small-label">Role</InputLabel>
          <Select
            required
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={role}
            label="role"
            onChange={handleChangeRole}
          >
            {[
              { value: "None", label: "None" },
              { value: "Admin", label: "Admin" },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        sx={{ my: 2 }}
        onClick={handleClick}
      >
        Login
      </LoadingButton>
    </>
  );
}
