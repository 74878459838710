import PropTypes from "prop-types";
import { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
// @mui
import { Box, List, ListItemText, Collapse } from "@mui/material";
// import { ExpandLess, Expand More } from '@mui/icons-material';
//
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import Iconify from "../iconify";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item, index) => (
          <NavItem
            key={item.title}
            item={item}
            open={openIndex === index}
            onClick={() => handleClick(index)}
          />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  onClick: PropTypes.func,
};

function NavItem({ item, open, onClick }) {
  const { title, path, icon, info, children } = item;

  if (children) {
    return (
      <>
        <StyledNavItem onClick={onClick} sx={{ cursor: "pointer" }}>
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
          <ListItemText disableTypography primary={title} />
          {open ? (
            <Iconify icon="mingcute:down-line" />
          ) : (
            <Iconify icon="mingcute:right-line" />
          )}
        </StyledNavItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child) => (
              <StyledNavItem
                key={child.title}
                component={RouterLink}
                to={child.path}
                sx={{
                  "&.active": {
                    color: "white",
                    bgcolor: "var(--main-color)",
                    fontWeight: "fontWeightBold",
                    borderRadius: "30px 0px 0px 30px",
                  },
                  pl: 4,
                }}
              >
                <StyledNavItemIcon>
                  {child.icon && child.icon}
                </StyledNavItemIcon>
                <ListItemText disableTypography primary={child.title} />
              </StyledNavItem>
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      className="pe-0"
      sx={{
        "&.active": {
          color: "white",
          bgcolor: "var(--main-color)",
          fontWeight: "fontWeightBold",
          borderRadius: "30px 0px 0px 30px",
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </StyledNavItem>
  );
}
