import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// components
import Iconify from "../../../components/iconify";
//
import AccountPopover from "./AccountPopover";
import { useEffect, useRef, useState } from "react";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const [showNotification, setShowNotification] = useState(false);
  const notificationRef = useRef(null);

  const handleButtonClick = () => {
    setShowNotification(!showNotification);
  };

  const handleClickOutside = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setShowNotification(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
            display: { lg: "none" },
          }}
        >
          <Iconify icon="arcticons:cbc" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />
        <div className="mx-3 position-relative">
          <Iconify
            icon={"hugeicons:notification-01"}
            onClick={handleButtonClick}
            className="text-black"
            style={{ cursor: "pointer" }}
          />
          {showNotification && (
            <div className="Notification-section zoomIn" ref={notificationRef}>
              <h6 className="title text-center mb-0">Notification</h6>
              <ul>
                <li>
                  <div className="d-flex flex-wrap justify-content-end p-2 message">
                    <p className="col-12 mb-0">
                      Breaking News: [Headline] Click to read more
                    </p>
                    <p className="col-8 text-end mb-0 timing">
                      07-07-2024 09:30AM
                    </p>
                  </div>
                </li>
                <li>
                  <div className="d-flex flex-wrap justify-content-end p-2 message">
                    <p className="col-12 mb-0">
                      Welcome to our app! Complete your profile to get started ,
                      Welcome to our app! Complete your profile to get started
                    </p>
                    <p className="col-8 text-end mb-0 timing">
                      07-07-2024 09:30AM
                    </p>
                  </div>
                </li>
              </ul>
              <h6 className="end-title text-center mb-0">All</h6>
            </div>
          )}
        </div>

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
