import { createSlice } from "@reduxjs/toolkit";

const LeadStageSlice = createSlice({
  name: "LeadStage",
  initialState: {
    Stage: "",
    Action: "",
  },
  reducers: {
    setLeadStage: (state, action) => {
      state.Token = action.payload;
    },
  },
});

export const { setLeadStage } = LeadStageSlice.actions;
export default LeadStageSlice.reducer;
