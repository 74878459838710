import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { useEffect, useState } from "react";
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useDispatch } from "react-redux";
import { showConfirmationDialog } from "../../utils/sweetalertUtils";
import Iconify from "../../components/iconify/Iconify";
import Scrollbar from "../../components/scrollbar/Scrollbar";
import { UserListHead } from "../../sections/@dashboard/user";
import {
  deleteData,
  getData,
  patchData,
  postData,
} from "../../webService/webService";
import { setView } from "../../redux/slices/LoaderSlice";
import SITEVISITROUTES from "../../API_Routes/SiteVisit/Sitevisit_Routers";

const initialFormData = {
  visitor: "",
  siteName: "",
  otherName: "",
  module: "",
  remark: "",
  visitStatus: "",
  notification: [],
  imageFileObject: {},
  location: {
    type: "Point",
    coordinates: [],
  },
  visitDate: "",
  timeIn: "",
  timeOut: "",
};
export default function Allsitevisit({ Role, Currentstatus }) {
  const TABLE_HEAD = [
    { id: "Site Name", label: "Site Name", alignRight: false },
    { id: "Module", label: "Module", alignRight: false },
    { id: "Other Name", label: "Other Name", alignRight: false },
    { id: "Visit Status", label: "Visit Status", alignRight: false },
    { id: "Visitor", label: "Visitor", alignRight: false },
    { id: "Action", label: "Action", alignRight: false },
  ];

  const dispatch = useDispatch();
  const [USERLIST, setUSERLIST] = useState(null);
  const accessToken = sessionStorage.getItem("Token");
  const [open, setOpen] = useState(null);
  const [active, setActive] = useState();
  const [selectedUserId, setSelectedUserId] = useState(null);
  console.log(USERLIST, "USERLIST123");

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedUserId(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };

  const [adduseropen, setadduserOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const handleadduserOpen = (data) => {
    setActive(data);
    setadduserOpen(true);
  };

  const handleadduserClose = () => setadduserOpen(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        notification: checked
          ? [...formData.notification, value]
          : formData.notification.filter((item) => item !== value),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async () => {
    debugger;
    dispatch(setView(true));
    if (active === "Add") {
      postData(SITEVISITROUTES.AddSITEVISIT, formData, accessToken)
        .then((res) => {
          console.log(res);
          setFormData(initialFormData);
          fetchdata(currentPage);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      handleadduserClose();
    } else {
      patchData(
        SITEVISITROUTES.PATCHPSITEVISIT(selectedUserId),
        formData,
        accessToken
      )
        .then((res) => {
          setOpen(null);
          fetchdata(currentPage);
          setFormData(initialFormData);
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      handleadduserClose();
    }
  };

  const fetchdata = (page) => {
    const params = {
      page: page,
      limit: limit,
      role: "Admin",
    };
    getData(SITEVISITROUTES.GETALLSITEVISIT, params, accessToken)
      .then((res) => {
        if (res.status === 404) {
          setUSERLIST(null);
          setTotalPages(1);
        } else {
          setUSERLIST(res.data[0].paginatedResults);
          setTotalPages(res.data[0].totalPages);
        }
        console.log(res);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchdata(currentPage);
  }, [currentPage, limit]);

  const DeleteId = async (val) => {
    debugger;
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      deleteData(SITEVISITROUTES.DELETESITEVISIT(val), accessToken)
        .then((res) => {
          setOpen(null);
          fetchdata(currentPage);
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  useEffect(() => {
    if (selectedUserId) {
      const EditUserData = USERLIST.find((item) => item._id === selectedUserId);
      setFormData({
        visitor: EditUserData.visitor || "",
        siteName: EditUserData.siteName || "",
        otherName: EditUserData.otherName || "",
        module: EditUserData.module || "",
        remark: EditUserData.remark || "",
        visitStatus: EditUserData.visitStatus || "",
        notification: EditUserData.notification || [],
        imageFileObject: EditUserData.imageFileObject || {},
        location: EditUserData.location || {
          type: "Point",
          coordinates: [],
        },
        visitDate: EditUserData.visitDate?.split('T')[0] || "",
        timeIn: EditUserData.timeIn?.replace('Z', '') || "",
        timeOut: EditUserData.timeOut?.replace('Z', '') || "",
      });
    }
  }, [selectedUserId]);

  return (
    <>
      <Helmet>
        <title>{Role}</title>
      </Helmet>

      <ToastContainer />

      <Modal open={adduseropen} onClose={handleadduserClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "60%",
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2">
            {active === "Add" ? "Add New Site Visit" : "Edit Site Visit"}
          </Typography>
          <Box component="form" sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Visitor"
                  name="visitor"
                  value={formData.visitor}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Site Name"
                  name="siteName"
                  value={formData.siteName}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Other Name"
                  name="otherName"
                  value={formData.otherName}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Module"
                  name="module"
                  value={formData.module}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Remark"
                  name="remark"
                  value={formData.remark}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="visitStatus-label">Visit Status</InputLabel>
                  <Select
                    labelId="visitStatus-label"
                    name="visitStatus"
                    value={formData.visitStatus}
                    onChange={handleChange}
                    label="Visit Status"
                  >
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                    <MenuItem value="Cancelled">Cancelled</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="notification-label">Notification</InputLabel>
                  <Select
                    labelId="notification-label"
                    name="notification"
                    value={formData.notification}
                    onChange={handleChange}
                    label="Notification"
                    multiple
                  >
                    <MenuItem value="Send SMS notification">
                      Send SMS notification
                    </MenuItem>
                    <MenuItem value="Send Email notification">
                      Send Email notification
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Location Coordinates"
                  name="location.coordinates"
                  value={formData.location.coordinates}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      location: {
                        ...formData.location,
                        coordinates: e.target.value.split(","),
                      },
                    })
                  }
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Visit Date"
                  name="visitDate"
                  type="date"
                  value={formData.visitDate}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Time In"
                  name="timeIn"
                  type="datetime-local"
                  value={formData.timeIn}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {" "}
                <TextField
                  label="Time Out"
                  name="timeOut"
                  type="datetime-local"
                  value={formData.timeOut}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <div className="d-flex justify-content-end w-100">
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
              >
                {active === "Add" ? "Add Site visit" : "Save Changes"}
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {Role}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => handleadduserOpen("Add")}
          >
            New Site Visit
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {USERLIST && USERLIST.length > 0 ? (
                    USERLIST.map((row) => {
                      const {
                        _id,
                        siteName,
                        module,
                        otherName,
                        visitStatus,
                        visitor,
                      } = row;
                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell align="left">{siteName}</TableCell>
                          <TableCell align="left">{module}</TableCell>
                          <TableCell align="left">{otherName}</TableCell>
                          <TableCell align="left">{visitStatus}</TableCell>
                          <TableCell align="left">{visitor}</TableCell>
                          <TableCell align="left">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => handleOpenMenu(event, _id)}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        <Typography variant="subtitle1" color="text.secondary">
                          No site visit's found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <div className="d-flex flex-wrap justify-content-end m-3">
            <FormControl variant="outlined">
              <InputLabel id="limit-label">Limit</InputLabel>
              <Select
                labelId="limit-label"
                id="limit-select"
                value={limit}
                onChange={handleLimitChange}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </FormControl>
            <Button onClick={handlePrevPage} disabled={currentPage === 1}>
              <Iconify icon={"grommet-icons:previous"} />
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <Iconify icon={"grommet-icons:next"} />
            </Button>
          </div>
        </Card>

        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem onClick={() => handleadduserOpen("Edit")}>
            <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => DeleteId(selectedUserId)}
            sx={{ color: "error.main" }}
          >
            <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>
      </Container>
    </>
  );
}
