import { Navigate, Routes, Route, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import UserPage from "./pages/UserPage";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import DashboardAppPage from "./pages/DashboardAppPage";
import CreateProperty from "./pages/Property/createProperty";
import AllProperty from "./pages/Property/allProperty";
import CreateProject from "./pages/Project/createProject";
import AllProject from "./pages/Project/allProject";
import CreateTeam from "./pages/Team/createTeam";
import AllTeam from "./pages/Team/allTeam";
import AllLeads from "./pages/Leads/allLeads";
import Allsitevisit from "./pages/SiteVisit/allsitevisit";
import Allbooking from "./pages/Booking/allbooking";

// ----------------------------------------------------------------------
export default function Router({ isAuthenticated, onLogin, onLogout }) {
  const routes = useRoutes([
    {
      path: "/admin",
      element:
        isAuthenticated === "Admin" ? (
          <DashboardLayout onLogout={onLogout} />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { path: "dashboard", element: <DashboardAppPage /> },
        { path: "team-lead", element: <UserPage Role={"Team-Lead"} /> },
        { path: "sub-admin", element: <UserPage Role={"Sub-Admin"} /> },
        { path: "agent", element: <UserPage Role={"Agent"} /> },
        {
          path: "pending-agent",
          element: (
            <UserPage Role={"Agent"} Currentstatus={"waiting for approval"} />
          ),
        },
        { path: "tele-caller", element: <UserPage Role={"Tele-Caller"} /> },
        {
          path: "sales-executive",
          element: <UserPage Role={"Sales-Executive"} />,
        },
        {
          path: "create-property",
          element: <CreateProperty />,
        },
        {
          path: "all-property",
          element: <AllProperty />,
        },
        {
          path: "create-project",
          element: <CreateProject />,
        },
        {
          path: "all-project",
          element: <AllProject />,
        },
        {
          path: "create-team",
          element: <CreateTeam />,
        },
        {
          path: "all-team",
          element: <AllTeam />,
        },
        {
          path: "all-leads",
          element: <AllLeads Currentstage={"BASIC"} />,
        },
        {
          path: "Prospects",
          element: <AllLeads Currentstage={"PROSPECT"} />,
        },
        {
          path: "Opportunity",
          element: <AllLeads Currentstage={"OPPURTUNITY"} />,
        },
        {
          path: "sitevisit",
          element: <Allsitevisit />,
        },
        {
          path: "bookings",
          element: <Allbooking />,
        },
      ],
    },
    {
      path: "login",
      element: isAuthenticated ? (
        <Navigate to="/dashboard/app" />
      ) : (
        <LoginPage onLogin={onLogin} />
      ),
    },
    {
      element: <SimpleLayout />,
      children: [
        {
          element: (
            <Navigate to={isAuthenticated ? "/dashboard/app" : "/login"} />
          ),
          index: true,
        },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
  return routes;
}
