import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  Grid,
  Card,
} from "@mui/material";
import { patchData, postData } from "../../webService/webService";
import LEADROUTES from "../../API_Routes/Lead/Lead_Routers";
const initialFormData = {
  mobileNumber: "",
  budget: "",
  bedConfiguration: "",
  score: "",
  clientName: "",
  clientContact: "",
  email: "",
  location: "",
  region: "",
  activityStatus: "",
  nationality: "",
  comments: "",
  reference: "",
  stage: "BASIC",
  requirement: "",
  followUpNote: "",
  source: "",
  branch: "",
  keywords: [],
  notification: [],
  visitDate: null,
  assignedDate: null,
  followUpDate: null,
  lastUpdatedDate: null,
  activityDate: null,
  posessionTimeline: null,
  prospect: {
    remarks: "",
  },
  oppurtunity: {
    remarks: "",
  },
};
const LeadForm = ({
  editData,
  handleCloseEdit,
  selectedUserId,
  viewMode,
  Currentstage,
}) => {
  const accessToken = sessionStorage.getItem("Token");
  const [leadData, setLeadData] = useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.startsWith("prospect") || name.startsWith("oppurtunity")) {
      const [obj, prop] = name.split(".");
      setLeadData({
        ...leadData,
        [obj]: {
          ...leadData[obj],
          [prop]: value,
        },
      });
    } else {
      setLeadData({ ...leadData, [name]: value });
    }
  };

  const handleMultiSelectChange = (event) => {
    const { value } = event.target;
    setLeadData({ ...leadData, notification: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (editData) {
      const res = await patchData(
        LEADROUTES.PATCHLEAD(selectedUserId),
        leadData,
        accessToken
      );
      if (res) {
        handleCloseEdit();
      }
    } else {
      const res = await postData(LEADROUTES.POSTLEAD, leadData, accessToken);
      if (res) {
        setLeadData(initialFormData);
      }
    }
  };
  useEffect(() => {
    if (editData) {
      console.log(editData, "editData");
      setLeadData(editData);
    }
  }, [editData]);

  return (
    <>
      <Card className="p-3">
        <h5 className="my-4" style={{ textTransform: "lowercase" }}>
          {viewMode
            ? `View ${Currentstage}`
            : editData
            ? `Edit ${Currentstage}`
            : "Create Lead"}
        </h5>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {Currentstage === "BASIC" && (
              <>
                {/* Text Fields */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Mobile Number"
                    name="mobileNumber"
                    value={leadData.mobileNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Budget"
                    name="budget"
                    value={leadData.budget}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Bed Configuration"
                    name="bedConfiguration"
                    value={leadData.bedConfiguration}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Score"
                    name="score"
                    value={leadData.score}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Client Name"
                    name="clientName"
                    value={leadData.clientName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Client Contact"
                    name="clientContact"
                    value={leadData.clientContact}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={leadData.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Location"
                    name="location"
                    value={leadData.location}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Region"
                    name="region"
                    value={leadData.region}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Activity Status"
                    name="activityStatus"
                    value={leadData.activityStatus}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Nationality"
                    name="nationality"
                    value={leadData.nationality}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Reference"
                    name="reference"
                    value={leadData.reference}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Comments"
                    name="comments"
                    value={leadData.comments}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Requirement"
                    name="requirement"
                    value={leadData.requirement}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Follow-up Note"
                    name="followUpNote"
                    value={leadData.followUpNote}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Source"
                    name="source"
                    value={leadData.source}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Branch"
                    name="branch"
                    value={leadData.branch}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Keywords"
                    name="keywords"
                    value={leadData.keywords.join(",")}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Notifications</InputLabel>
                    <Select
                      multiple
                      value={leadData.notification}
                      onChange={handleMultiSelectChange}
                      renderValue={(selected) => selected.join(", ")}
                      name="notification"
                    >
                      <MenuItem value="Send SMS notification to Assignee">
                        Send SMS notification to Assignee
                      </MenuItem>
                      <MenuItem value="Send SMS notification to Customer">
                        Send SMS notification to Customer
                      </MenuItem>
                      <MenuItem value="Send Email notification to Assignee">
                        Send Email notification to Assignee
                      </MenuItem>
                      <MenuItem value="Send Email notification to Customer">
                        Send Email notification to Customer
                      </MenuItem>
                      <MenuItem value="Terms and conditions shared">
                        Terms and conditions shared
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Visit Date"
                    type="date"
                    name="visitDate"
                    value={leadData.visitDate}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Assigned Date"
                    type="date"
                    name="assignedDate"
                    value={leadData.assignedDate}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Follow-up Date"
                    type="date"
                    name="followUpDate"
                    value={leadData.followUpDate}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Last Updated Date"
                    type="date"
                    name="lastUpdatedDate"
                    value={leadData.lastUpdatedDate}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Activity Date"
                    type="date"
                    name="activityDate"
                    value={leadData.activityDate}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Possession Timeline"
                    type="date"
                    name="posessionTimeline"
                    value={leadData.posessionTimeline}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Stage</InputLabel>
                {Currentstage === "BASIC" && (
                  <Select
                    value={leadData.stage || ""}
                    onChange={handleChange}
                    name="stage"
                  >
                    <MenuItem value="BASIC">BASIC</MenuItem>
                    <MenuItem value="PROSPECT">PROSPECT</MenuItem>
                  </Select>
                )}
                {Currentstage === "PROSPECT" && (
                  <Select
                    value={leadData.stage || ""}
                    onChange={handleChange}
                    name="stage"
                  >
                    <MenuItem value="PROSPECT">PROSPECT</MenuItem>
                    <MenuItem value="OPPURTUNITY">OPPURTUNITY</MenuItem>
                  </Select>
                )}
                {Currentstage === "OPPURTUNITY" && (
                  <Select
                    value={leadData.stage || ""}
                    onChange={handleChange}
                    name="stage"
                  >
                    <MenuItem value="OPPURTUNITY">OPPURTUNITY</MenuItem>
                  </Select>
                )}
              </FormControl>
            </Grid>
            {/* Prospect */}
            {leadData.stage === "PROSPECT" ? (
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Prospect Remarks"
                  name="prospect.remarks"
                  value={leadData?.prospect?.remarks}
                  onChange={handleChange}
                />
              </Grid>
            ) : (
              ""
            )}
            {/* Prospect */}

            {/*  oppurtunity */}
            {leadData.stage === "OPPURTUNITY" ? (
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="oppurtunity Remarks"
                  name="oppurtunity.remarks"
                  value={leadData?.oppurtunity?.remarks}
                  onChange={handleChange}
                />
              </Grid>
            ) : (
              ""
            )}
            {/*  oppurtunity */}

            <div className="d-flex justify-content-end mt-4 w-100">
              <Button
                variant="contained"
                className="btn-danger"
                onClick={handleCloseEdit}
              >
                Close
              </Button>
              {!viewMode && (
                <Button type="submit" variant="contained" className="btn-green">
                  Submit
                </Button>
              )}
            </div>
            {/* <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Grid> */}
          </Grid>
        </form>
      </Card>
    </>
  );
};

export default LeadForm;
