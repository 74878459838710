import { useEffect, useState } from "react";
import { deleteData, getData } from "../../webService/webService";
import {
  Button,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Card,
} from "@mui/material";
import Scrollbar from "../../components/scrollbar";
import { UserListHead } from "../../sections/@dashboard/user";
import Iconify from "../../components/iconify";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { showConfirmationDialog } from "../../utils/sweetalertUtils";
import TEAMROUTES from "../../API_Routes/Team/Team_Routers";
import CreateTeam from "./createTeam";

const AllTeam = () => {
  // Table
  const accessToken = sessionStorage.getItem("Token");
  const [open, setOpen] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [USERLIST, setUSERLIST] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedUserId(id);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };
  const fetchdata = (page) => {
    const params = {
      page: page,
      limit: limit,
    };
    getData(TEAMROUTES.GETALLTEAM, params, accessToken)
      .then((res) => {
        console.log(res);
        setUSERLIST(res.data[0].paginatedResults);
        setTotalPages(res.data[0].totalPages);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    fetchdata(currentPage);
  }, [currentPage, limit]);

  const TABLE_HEAD = [
    { id: "Name", label: "Team Title", alignRight: false },
    { id: "branch", label: "Branch", alignRight: false },
    { id: "Action", label: "Action", alignRight: false },
  ];
  const filteredData = (data) => {
    const filterval = data.map(({ _id: value, firstName: label }) => ({
      value,
      label,
    }));
    console.log(filterval, "filterval");
    return filterval;
  };

  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [viewMode, setViewMode] = useState(false);
  const Handleshow = (data) => {
    const EditUserData = USERLIST.find((item) => item._id === selectedUserId);
    console.log(EditUserData, "EditUserData");
    if (data === "View") {
      setViewMode(true);
    }
    setShowEdit(true);
    setEditData({
      teamTitle: EditUserData.name,
      branch: EditUserData.branch,
      teamLeader: filteredData(EditUserData.TeamLead_Data),
      teleCallers: filteredData(EditUserData.TeleCallers_Data),
      salesExecutives: filteredData(EditUserData.SalesExecutive_Data),
    });
  };
  const handleCloseEdit = () => {
    setShowEdit(false);
    setViewMode(false);
    setEditData(null);
    setOpen(null);
    fetchdata(currentPage);
  };

  const DeleteId = async (val) => {
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      deleteData(TEAMROUTES.DELETETEAM(val), accessToken)
        .then((res) => {
          setOpen(null);
          fetchdata(currentPage);
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>All Team</title>
      </Helmet>

      <ToastContainer />
      {showEdit ? (
        <>
          <CreateTeam
            editData={editData}
            handleCloseEdit={handleCloseEdit}
            selectedUserId={selectedUserId}
            viewMode={viewMode}
          />
        </>
      ) : (
        <>
          <Container>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography variant="h4" gutterBottom>
                All Teams
              </Typography>
            </Stack>

            <Card>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      headLabel={TABLE_HEAD}
                      rowCount={USERLIST?.length}
                    />
                    <TableBody>
                      {USERLIST?.map((row) => {
                        const { _id, name, branch } = row;
                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                  style={{ marginLeft: "10px" }}
                                >
                                  {name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{branch}</TableCell>
                            <TableCell align="left">
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(event) => handleOpenMenu(event, _id)}
                              >
                                <Iconify icon={"eva:more-vertical-fill"} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <div className="d-flex flex-wrap justify-content-end m-3">
                <FormControl variant="outlined" size="small">
                  <InputLabel id="limit-label">Limit</InputLabel>
                  <Select
                    labelId="limit-label"
                    id="limit-select"
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                  </Select>
                </FormControl>
                <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                  <Iconify icon={"grommet-icons:previous"} />
                </Button>
                <Button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <Iconify icon={"grommet-icons:next"} />
                </Button>
              </div>
            </Card>
          </Container>

          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                "& .MuiMenuItem-root": {
                  px: 1,
                  typography: "body2",
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                Handleshow("View");
              }}
            >
              <Iconify icon={"iconoir:eye-solid"} sx={{ mr: 2 }} />
              View
            </MenuItem>
            <MenuItem
              onClick={() => {
                Handleshow("Edit");
              }}
            >
              <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
              Edit
            </MenuItem>
            <MenuItem
              sx={{ color: "error.main" }}
              onClick={() => {
                DeleteId(selectedUserId);
              }}
            >
              <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
              Delete
            </MenuItem>
          </Popover>
        </>
      )}
    </>
  );
};
export default AllTeam;
