import React, { useEffect, useState } from "react";
import { Button, Card, TextField } from "@mui/material";
import ReusablePaginatedSelect from "../../components/select-pagination";
import { getData, patchData, postData } from "../../webService/webService";
import USERSROUTES from "../../API_Routes/User/User_Routers";
import TEAMROUTES from "../../API_Routes/Team/Team_Routers";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";

const initialFormData = {
  teamTitle: "",
  branch: "",
  teamLeader: null,
  teleCallers: [],
  salesExecutives: [],
};

function CreateTeam({ editData, handleCloseEdit, selectedUserId, viewMode }) {
  const accessToken = sessionStorage.getItem("Token");
  const [teamData, setTeamData] = useState(initialFormData);
  useEffect(() => {
    if (editData) {
      setTeamData(editData);
    }
  }, [editData]);
  console.log(teamData, "teamData");

  const getFetchDataFunction = (type) => {
    switch (type) {
      case "Team-Lead":
        return fetchTeamLeadData;
      case "Tele-Caller":
        return fetchTeleCallerData;
      case "Sales-Executive":
        return fetchSalesExecutiveData;
      default:
        return null;
    }
  };

  const fetchTeamLeadData = async (params, token) => {
    try {
      const response = await getData(
        USERSROUTES.GETUSER("Team-Lead"),
        params,
        token
      );
      return response;
    } catch (error) {
      console.error("Error fetching Team Lead data:", error);
      return { data: [], totalPages: 0 }; // Return empty data if there's an error
    }
  };

  const fetchTeleCallerData = async (params, token) => {
    try {
      const response = await getData(
        USERSROUTES.GETUSER("Tele-Caller"),
        params,
        token
      );
      return response;
    } catch (error) {
      console.error("Error fetching Tele Caller data:", error);
      return { data: [], totalPages: 0 }; // Return empty data if there's an error
    }
  };

  const fetchSalesExecutiveData = async (params, token) => {
    try {
      const response = await getData(
        USERSROUTES.GETUSER("Sales-Executive"),
        params,
        token
      );
      return response;
    } catch (error) {
      console.error("Error fetching Sales Executive data:", error);
      return { data: [], totalPages: 0 }; // Return empty data if there's an error
    }
  };

  const getOptions = (res) => {
    return res.data[0].paginatedResults.map((item) => ({
      value: item._id,
      label: item.firstName,
    }));
  };

  const handleInputChange = (field, value) => {
    setTeamData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const CreateTeamNew = async () => {
    const teleCallerIds = teamData.teleCallers.map((item) => item.value);
    const salesExecutiveIds = teamData.salesExecutives.map(
      (item) => item.value
    );
    const params = {
      name: teamData.teamTitle,
      branch: teamData.branch,
      teamLeadId: teamData.teamLeader.value,
      teleCallersWithLeads: {
        teleCallerIds: teleCallerIds,
      },
      salesExecutiveIds: salesExecutiveIds,
    };
    if (editData) {
      const res = await patchData(
        TEAMROUTES.PATCHTEAM(selectedUserId),
        params,
        accessToken
      );
      if (res) {
        handleCloseEdit();
      }
    } else {
      const res = await postData(TEAMROUTES.ADDTEAM, params, accessToken);
      if (res) {
        setTeamData(initialFormData);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>
          {viewMode ? "View Team" : editData ? "Edit Team" : "Create Team"}
        </title>
      </Helmet>
      <Card className="p-3 h-100" style={{ height: "100vh" }}>
        <h5>
          {viewMode ? "View Team" : editData ? "Edit Team" : "Create Team"}
        </h5>
        <div className="d-flex flex-wrap">
          <div className="col-6 px-1">
            <TextField
              name="Team Title"
              label="Team Title"
              value={teamData.teamTitle}
              onChange={(e) => handleInputChange("teamTitle", e.target.value)}
              fullWidth
              margin="normal"
              className="my-3"
              size="small"
              required
            />
          </div>
          <div className="col-6 px-1">
            <TextField
              name="branch"
              label="Branch"
              value={teamData.branch}
              onChange={(e) => handleInputChange("branch", e.target.value)}
              fullWidth
              margin="normal"
              className="my-3"
              size="small"
              required
            />
          </div>
        </div>

        <div className="d-flex flex-wrap mt-3">
          <div className="col-4 px-1">
            <p className="fw-bold">Select Team Leader</p>
            <ReusablePaginatedSelect
              fetchDataFunction={getFetchDataFunction("Team-Lead")}
              accessToken={accessToken}
              getOptions={getOptions}
              onChange={(value) => handleInputChange("teamLeader", value)}
              value={teamData.teamLeader}
            />
          </div>
          <div className="col-4 px-1">
            <p className="fw-bold">Select Tele Caller(s)</p>
            <ReusablePaginatedSelect
              fetchDataFunction={getFetchDataFunction("Tele-Caller")}
              accessToken={accessToken}
              getOptions={getOptions}
              onChange={(values) => handleInputChange("teleCallers", values)}
              value={teamData.teleCallers}
              isMulti={true}
            />
          </div>
          <div className="col-4 px-1">
            <p className="fw-bold">Select Sales Executive(s)</p>
            <ReusablePaginatedSelect
              fetchDataFunction={getFetchDataFunction("Sales-Executive")}
              accessToken={accessToken}
              getOptions={getOptions}
              onChange={(values) =>
                handleInputChange("salesExecutives", values)
              }
              value={teamData.salesExecutives}
              isMulti={true}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <Button
            variant="contained"
            className="btn-danger"
            onClick={handleCloseEdit}
          >
            Close
          </Button>
          {!viewMode && (
            <Button
              onClick={CreateTeamNew}
              variant="contained"
              className="btn-green"
            >
              Submit
            </Button>
          )}
        </div>
      </Card>
    </>
  );
}

export default CreateTeam;
